import { VArchive_Components } from "../VArchive_Components";

export class VA_EditableInput extends HTMLElement {
    public static readonly NAME: string = "va-editable-input";

    private static readonly TEMPLATE: string = `
<style></style>
<div class="ei-plain">
    <div class="ei-plain-value"></div>
</div>
<div class="ei-editing"></div>
<div class="ei-plain-edit">
    <a class="ei-plain-edit-unopened">Edit</a>
    <a class="ei-plain-edit-opened">Cancel</a>
</div>
    `;

    private static readonly ATTR_EDITING: string = "editing";

    private static readonly ATTR_VALUE: string = "value";

    private static readonly ATTR_EDITTEXT: string = "text-edit";

    private static readonly ATTR_EDITTEXT_HREF: string = "text-edit-href";

    private valueDisplayField: HTMLDivElement;

    private plainEditLinkUnopened: HTMLLinkElement;

    private plainEditLinkOpened: HTMLLinkElement;

    private _value: string;

    public set value(value: string) {
        this._value = value;

        this.valueDisplayField.innerText = value;
    }

    public get value(): string {
        return this._value;
    }

    private static readonly createStyle = (styleElem: HTMLStyleElement) => {
        styleElem.innerHTML = `
        va-editable-input {
            display: flex;
            flex-direction: row;
            width: 100%;
            color: #afafaf;
        }
        va-editable-input > *:not(.ei-plain-edit) {
            flex-grow: 1;
        }
        va-editable-input a {
            color: ${VArchive_Components.THEME_COLOR};
            cursor: pointer;
        }
        va-editable-input:not([editing="true"]) .ei-editing,
        va-editable-input:not([editing="true"]) .ei-plain-edit-opened,
        va-editable-input[editing="true"] .ei-plain,
        va-editable-input[editing="true"] .ei-plain-edit-unopened {
            display: none;
        }
        .ei-plain {
            display: flex;
            flex-direction: row;
        }
        .ei-plain-value {
            flex-grow: 1;
        }
    `;
    };

    public constructor() {
        super();

        const previousHtml = this.innerHTML;
        this.innerHTML = VA_EditableInput.TEMPLATE;
        this.querySelector(".ei-editing").innerHTML = previousHtml;

        this.valueDisplayField = this.querySelector(".ei-plain-value");

        if (!this.hasAttribute(VA_EditableInput.ATTR_EDITING)) {
            this.setAttribute(VA_EditableInput.ATTR_EDITING, "false");
        }

        addEventListener("keydown", this.onGlobalKeyDown);

        this.plainEditLinkUnopened = this.querySelector(".ei-plain-edit-unopened");
        this.plainEditLinkUnopened.addEventListener("click", this.onEditUnopenedClick);

        this.plainEditLinkOpened = this.querySelector(".ei-plain-edit-opened");
        this.plainEditLinkOpened.addEventListener("click", this.onEditOpenedClick);
    }

    public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        switch (name) {
            case VA_EditableInput.ATTR_EDITING:
                if (newValue !== "true" && newValue !== "false") {
                    this.setAttribute(VA_EditableInput.ATTR_EDITING, oldValue ? oldValue : "false");
                }
                break;
            case VA_EditableInput.ATTR_EDITTEXT:
                this.plainEditLinkUnopened.innerText = newValue;
                break;
            case VA_EditableInput.ATTR_EDITTEXT_HREF:
                this.plainEditLinkUnopened.href = newValue;
                break;
            case VA_EditableInput.ATTR_VALUE:
                this.value = newValue;
                break;
            default:
                break;
        }
    }

    public static get observedAttributes() {
        return [VA_EditableInput.ATTR_VALUE, VA_EditableInput.ATTR_EDITING, VA_EditableInput.ATTR_EDITTEXT, VA_EditableInput.ATTR_EDITTEXT_HREF];
    }

    private onGlobalKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            this.setAttribute(VA_EditableInput.ATTR_EDITING, "false");
        }
    };

    private onEditUnopenedClick = (e: MouseEvent) => {
        if (!this.plainEditLinkUnopened.href) {
            this.setAttribute(VA_EditableInput.ATTR_EDITING, "true");
        }
    };

    private onEditOpenedClick = (e: MouseEvent) => {
        this.setAttribute(VA_EditableInput.ATTR_EDITING, "false");
    };
}
