import { VA_Toggle } from "./elements/VA_Toggle";
import { VA_EditableInput } from "./elements/VA_EditableInput";

export class VArchive_Components {
    public static readonly THEME_COLOR: string = "#CC181E";

    public static readonly ELEMENTS: Array<typeof HTMLElement> = [VA_EditableInput, VA_Toggle];

    public constructor() {
        for (const element of VArchive_Components.ELEMENTS) {
            customElements.define(element["NAME"], element);

            if ("createStyle" in element && typeof element["createStyle"] === "function") {
                const elem = document.createElement("style");
                elem.setAttribute("for", element["NAME"]);

                (element["createStyle"] as (HTMLStyleElement) => void)(elem);

                document.head.appendChild(elem);
            }
        }
    }
}

new VArchive_Components();
