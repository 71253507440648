import { VArchive_Components } from "../VArchive_Components";

export class VA_Toggle extends HTMLElement {
    public static readonly NAME: string = "va-toggle";

    private static readonly ATTR_BACKGROUND: string = "background";

    private static readonly ATTR_COLOR: string = "color";

    private static readonly ATTR_TRACK_COLOR: string = "trackcolor";

    private static readonly ATTR_HANDLE_COLOR: string = "handlecolor";

    private static readonly ATTR_CHECKED: string = "checked";

    public bgColor: string;

    public color: string;

    public trackColor: string;

    public handleColor: string;

    private backgroundColor(elem: VA_Toggle_Strings): string {
        if (this[elem]) {
            return `background: ${this[elem]};`;
        } else {
            return "";
        }
    }

    public constructor() {
        super();

        const shadow = this.attachShadow({ mode: "open" }),
            styleElem = document.createElement("style"),
            handleElem = document.createElement("div"),
            trackElem = document.createElement("div");

        handleElem.id = "toggle-handle";

        trackElem.id = "toggle-track";

        shadow.appendChild(styleElem);
        shadow.appendChild(handleElem);
        shadow.appendChild(trackElem);

        if (!this.hasAttribute(VA_Toggle.ATTR_CHECKED)) {
            this.setAttribute(VA_Toggle.ATTR_CHECKED, "false");
        }

        this.addEventListener("click", this.toggle);
    }

    private updateStyle = () => {
        this.shadowRoot.querySelector("style").textContent = `
        :host {
            display: block;
            position: relative;
            width: 34px;
            height: 14px;
            background: #545454;${this.backgroundColor("bgColor")}
            border-radius: 10px;
            margin-left: 10px;
            cursor: pointer;
        }
        #toggle-handle {
            position: absolute;
            height: 20px;
            width: 20px;
            background: ${VArchive_Components.THEME_COLOR};${this.backgroundColor("color")}${this.backgroundColor("handleColor")}
            margin-top: -3px;
            border-radius: 50%;
            transition: right 0.2s;
            right: 18px;
            z-index: 1;
        }
        #toggle-track {
            width: 0%;
            height: 100%;
            background: ${VArchive_Components.THEME_COLOR};${this.backgroundColor("color")}${this.backgroundColor("trackColor")}
            border-radius: 10px;
            filter: brightness(0.75);
            transition: width 0.2s;
        }
        :host([checked="true"]) #toggle-handle {
            right: -4px;
        }
        :host([checked="true"]) #toggle-track {
            width: 100%;
        }
        `;
    };

    private connectedCallback() {
        this.updateStyle();
    }

    public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        switch (name) {
            case VA_Toggle.ATTR_BACKGROUND:
                this.bgColor = newValue;
                break;
            case VA_Toggle.ATTR_COLOR:
                this.color = newValue;
                break;
            case VA_Toggle.ATTR_TRACK_COLOR:
                this.trackColor = newValue;
                break;
            case VA_Toggle.ATTR_HANDLE_COLOR:
                this.handleColor = newValue;
                break;
            case VA_Toggle.ATTR_CHECKED:
                if (newValue !== "true" && newValue !== "false") {
                    this.setAttribute(VA_Toggle.ATTR_CHECKED, oldValue ? oldValue : "false");
                }
                break;
            default:
                break;
        }

        this.updateStyle();
    }

    public static get observedAttributes() {
        return [VA_Toggle.ATTR_BACKGROUND, VA_Toggle.ATTR_COLOR, VA_Toggle.ATTR_TRACK_COLOR, VA_Toggle.ATTR_HANDLE_COLOR, VA_Toggle.ATTR_CHECKED];
    }

    public toggle = () => {
        this.setAttribute(VA_Toggle.ATTR_CHECKED, `${this.getAttribute(VA_Toggle.ATTR_CHECKED) !== "true"}`);
    };
}

type VA_Toggle_Strings = {
    [K in keyof VA_Toggle]: VA_Toggle[K] extends string ? K : never
}[keyof VA_Toggle];
